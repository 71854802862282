<template>
    <Toolbar>
        <template v-slot:left>
            <Button icon="pi pi-user" label="My Tickets" text raised
                :class="myAssignedAndTeamTicketStatus == 2 ? 'p-mr-2 p-button' : 'p-mr-2 p-button-outlined'"
                @click="getMyTickets()" />
            <Button icon="pi pi-users" label="Team's Tickets" text raised
                :class="myAssignedAndTeamTicketStatus == 2 ? 'p-mr-2 p-button-outlined' : 'p-mr-2 p-button'"
                @click="getMyTeamsTickets()" />
        </template>

        <template v-slot:right>
            <Button label="Filter" icon="pi pi-filter-slash" class="p-button-success" @click="filterlisting()"></Button>
        </template>
    </Toolbar>
    <Toast />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <DataTable :value="openTicketList" :lazy="true" :paginator="true" :rows="30"
                    :totalRecords="totalRecords" :loading="loading" @page="onPage($event)" class="p-datatable-users"
                    data-key="sa1" :rowHover="true"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">

                    <template #empty> No data found. </template>

                    <template #loading> Loading data Please wait... </template>
                    <Column header="Sr." headerStyle="width: 5%">

                        <template #body="{ index }">
                            <div>
                                <div>{{ index+=1 }}</div>
                            </div>
                        </template>
                    </Column>
                    <Column header="Admin/Host Information" headerStyle="width: 20%">

                        <template #body="{ data }">
                            <div>
                                <span class="p-text-bold">Ticket Raised By : </span>
                                <span class='user-badge status-active' v-if="data.sa39 == 1">ADMIN</span>
                                <span class='user-badge status-temp-suspend' v-if="data.sa39 == 2">HOST</span>
                            </div>
                            <div class="p-text-capitalize">{{ data.sa39 == 1 ? data.sa3 : data.sa38 }}</div>
                            <div class="p-text-capitalize" v-if="data.sa39 == 2">({{ data.sa3 }})</div>
                        </template>
                    </Column>
                    <Column header="Ticket Details" headerStyle="width: 40%">

                        <template #body="{ data }">
                            <div>
                                <div><span class="p-text-bold">Ticket Opened :</span> {{ data.sa13 ?
                    format_timestamp(data.sa13) : 'N/A' }}</div>
                                by <span class="p-text-capitalize">{{ data.sa15 ? data.sa15 : 'N/A' }}</span>
                            </div>
                            <Divider type="dashed" class="p-my-2" />
                            <div>
                                <div class="p-mb-1 p-d-flex p-jc-between">
                                    <span><span class="p-text-bold">Ticket Number : </span><span class="indigo-color">{{
                    data.sa4 ? data.sa4 : 'N/A' }}</span></span>
                                    <span>
                                        <span class="p-text-bold custom-status">Status: </span>
                                        <span v-if="data.sa18 == 1" :class="'user-badge status-active'">New</span>
                                        <span v-if="data.sa18 == 2" :class="'user-badge status-active'">Open</span>
                                        <span v-if="data.sa18 == 3"
                                            :class="'user-badge status-temp-suspend'">Closed</span>
                                        <span v-if="data.sa18 == 4"
                                            :class="'user-badge status-per-suspend'">Cancelled</span>
                                        <span v-if="data.sa18 == '' || data.sa18 == null">N/A</span>
                                    </span>
                                </div>
                                <div>
                                    <span class="p-text-bold">Facing Problem In : </span><span v-if="data.sa5 == 1">Web
                                        Application</span>
                                    <span v-if="data.sa5 == 2">Android Application</span>
                                    <span v-if="data.sa5 == 3">IOS Application</span>
                                    <span v-if="data.sa5 == 4">All</span>
                                    <span v-if="data.sa5 == '' || data.sa5 == null">N/A</span>
                                </div>
                            </div>
                            <Divider type="dashed" class="p-my-2" />
                            <div v-if="data.sb5 != null"><span class="p-text-bold">Description : </span>
                                <span v-if="!activate_read_key_arr.includes(data.sa1)">{{
                    data.sb5.slice(0,
                        140).replaceAll('*', '') }} </span>
                                <a v-if="data.sb5.length >= 140 && !activate_read_key_arr.includes(data.sa1)"
                                    @click="activateReadMore(data.sa1)" class="showmore p-ml-2">read more</a>
                                <span v-if="activate_read_key_arr.includes(data.sa1)"
                                    v-html="data.sb5.replaceAll('*', '')"></span>
                                <span>
                                    <a v-if="data.sb5.length >= 140 && activate_read_key_arr.includes(data.sa1)"
                                        @click="deactivateReadMore(data.sa1)" class="showless p-ml-2">read less</a>
                                </span>
                            </div>
                            <div v-else><span class="p-text-bold">Description : </span>N/A</div>
                            <Divider type="dashed" class="p-my-2" />
                            <div>
                                <div class="p-mb-1"><span class="p-text-bold">Effected URL : </span>{{ data.sa22 ?
                    data.sa22 : 'N/A' }}
                                </div>
                                <span class="p-text-bold">Effected User : </span><span class="p-text-capitalize">{{
                    data.sa24 ? data.sa24 : 'N/A' }}</span>
                            </div>
                        </template>
                    </Column>
                    <Column header="Support Action" headerStyle="width: 35%">

                        <template #body="{ data }">
                            <div>
                                <div class="p-mb-1">
                                    <span class="p-text-bold">Ticket Assign To : </span><span
                                        class="p-text-capitalize">{{
                    data.sa17 ? data.sa17 : 'N/A' }}</span>
                                </div>
                                <div>on <span>{{ data.sa13 ?
                    format_timestamp(data.sa13) : 'N/A' }}</span></div>
                                <div><span class="p-text-bold">Total Time Taken :</span> <span>{{ working_time(data)
                                        }}</span>
                                </div>
                            </div>
                            <Divider type="dashed" class="p-my-2" />
                            <div>
                                <span class="p-text-bold">Third Party Dependency : </span>
                                <span v-if="data.sa26 == 1">Yes</span>
                                <span v-else>No</span>
                            </div>
                            <Divider type="dashed" class="p-my-2" />
                            <div class="p-d-flex p-jc-between">
                                <div>
                                    <span class="p-text-bold">Ticket Type : </span>
                                    <span v-if="data.sa6 == 1">Technical</span>
                                    <span v-if="data.sa6 == 2">Logical/Navigational</span>
                                    <span v-if="data.sa6 == '' || data.sa6 == null">N/A</span>
                                </div>
                                <div><Button label="Show History" severity="info" @click="showHistoryDialogOpen(data)"
                                        class="p-button-text p-py-0" />
                                </div>
                            </div>
                            <Divider type="dashed" class="p-my-2" />
                            <div>
                                <div class="p-mb-1"><span class="p-text-bold">Ticket Closed :</span> {{ data.sa32 ?
                    format_timestamp(data.sa32) : 'N/A' }}</div>
                                <div>by <span class="p-text-capitalize">{{ data.sa34 ? data.sa34 : 'N/A' }}</span></div>
                            </div>
                        </template>
                    </Column>
                </DataTable>
                <Toast />
            </div>
        </div>
    </div>
    <!-- show history dialog start here -->
    <div class="modal-mask">
        <Dialog v-model:visible="showHistoryDialogStatus" :style="{ width: '1000px' }" header="Ticket History"
            :modal="true" class="p-fluid">
            <div class="card custom-ticket-header" ref="chatBoxElement">
                <Toast />
                <div class="p-pt-2 p-px-3">
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-12 p-md-4 p-mb-0">
                            <div><span class="p-text-bold">Admin Name :</span> <span class="p-text-capitalize"> {{
                    this.ticketHistoryData.sa3 ? this.ticketHistoryData.sa3 : 'N/A' }}</span> </div>
                            <div v-if="this.ticketHistoryData.sa39 == 2"><span class="p-text-bold">Host Name :</span>
                                <span class="p-text-capitalize"> {{
                    this.ticketHistoryData.sa38 ? this.ticketHistoryData.sa38 : 'N/A' }}</span>
                            </div>
                        </div>
                        <div class="p-field p-col-12 p-md-4 p-mb-0">
                            <div><span class="p-text-bold">Ticket No :</span> {{ this.ticketHistoryData.sa4 ?
                    this.ticketHistoryData.sa4 : 'N/A' }} </div>
                        </div>
                        <div class="p-field p-col-12 p-md-4 p-mb-0">
                            <div class="p-mb-1"><span class="p-text-bold">Ticket By :</span> <span
                                    class="p-text-capitalize">{{
                    this.ticketHistoryData.sa31 ? this.ticketHistoryData.sa31 : 'N/A' }}</span>
                            </div>
                            <div v-if="this.ticketHistoryData.maf6 != '' && this.ticketHistoryData.maf6 != null"
                                class="p-d-flex p-dir-row p-ai-center"><Button icon="pi pi-phone"
                                    class="ticket-custom-call-btn p-button-success"
                                    @click="triggerCallToMeraNetaClients(this.ticketHistoryData)"
                                    :disabled="isDisabled">
                                </Button>+91{{ this.ticketHistoryData.maf6 ? this.ticketHistoryData.maf6 : 'N/A'
                                }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="ticket-chat-box-outer">
                <div class="history-conversation-inner">
                    <div v-for="msg in ticketMessage" :key="msg">
                        <div class="msg-body-align-left" v-if="msg.sb12 == 1">
                            <div class="p-d-flex p-jc-between custom-datetime-label">
                                <span class="p-text-bold p-text-capitalize">{{ msg.sb11 ? msg.sb11 : 'N/A' }}</span>
                                <span class="msg-datetime-left">{{ msg.z501 ? format_timestamp(msg.z501) : 'N/A'
                                    }}</span>
                            </div>
                            <span class="image-profile-outer-left"><img
                                    v-if="msg.profileimageurl == null || msg.profileimageurl == ''"
                                    src="/assets/layout/images/widgets/asset-profile-11.png" class="custom-profile-chat"
                                    alt="" width="32" height="32" />
                                <img v-if="msg.profileimageurl != null && msg.profileimageurl != ''"
                                    class="custom-profile-chat" :src="msg.profileimageurl" alt="" width="32"
                                    height="32" />
                            </span>
                            <div class="message-body-left-arrow">
                                <div class="left-arrow-outer"></div>
                                <div class="left-arrow-inner"></div>
                            </div>
                            <div class="message-body-left">
                                <p><span class="p-text-capitalize">{{ msg.sb5 ? msg.sb5 : 'N/A' }}</span>
                                </p>
                            </div>

                            <div v-if="msg.sb6 != '' && msg.sb6 != null">
                                <span class="attachment-left"><i class="pi pi-paperclip me-1"></i>Attachment's : </span>
                                <span v-for="(value, key) in splitJoin(msg.sb6)" :key="key">
                                    <img :src="value" alt="" width="32" height="32" class="attach-file"
                                        @click="previewImageModalOpen(value)" />
                                </span>
                            </div>

                        </div>

                        <div class="msg-body-align-right" v-if="msg.sb12 == 2">
                            <div class="p-d-flex p-jc-between custom-datetime-label">
                                <span class="msg-datetime-right">{{ msg.z501 ? format_timestamp(msg.z501) : 'N/A'
                                    }}</span>
                                <span class="p-text-bold"><span class="p-text-capitalize">{{ msg.sb11 ? msg.sb11 : 'N/A'
                                        }}</span></span>
                            </div>
                            <div>
                                <span class="image-profile-outer-right"><img
                                        v-if="msg.profileimageurl == null || msg.profileimageurl == ''"
                                        src="/assets/layout/images/widgets/asset-profile-11.png"
                                        class="custom-profile-chat" alt="" width="32" height="32" />
                                    <img v-if="msg.profileimageurl != null && msg.profileimageurl != ''"
                                        :src="msg.profileimageurl" class="custom-profile-chat" alt="" width="32"
                                        height="32" />
                                </span>
                                <div class="message-body-right-arrow">
                                    <div class="right-arrow-outer"></div>
                                    <div class="right-arrow-inner"></div>
                                </div>
                                <div class="message-body-right">
                                    <p><span class="p-text-capitalize">{{ msg.sb5 ? msg.sb5 : 'N/A' }}</span>
                                    </p>
                                </div>
                            </div>
                            <div v-if="msg.sb6 != '' && msg.sb6 != null" class="attachment-right-outer">
                                <span class="attachment-right"><i class="pi pi-paperclip me-1"></i>Attachment's :
                                </span>
                                <span v-for="(value, key) in splitJoin(msg.sb6)" :key="key">
                                    <img :src="value" alt="" width="32" height="32" class="attach-file"
                                        @click="previewImageModalOpen(value)" />
                                </span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="p-pt-2 p-px-3">
                    <TabView class="tabview-custom">
                        <TabPanel>

                            <template #header>
                                <div class="flex align-items-center gap-2">
                                    <i class="pi pi-file"></i>
                                    <span class="font-bold white-space-nowrap"> Call Recordings</span>
                                </div>
                            </template>
                            <div>No Records Found
                            </div>
                        </TabPanel>
                    </TabView>
                </div>
            </div>

            <template #footer>
                <div>

                </div>
            </template>
        </Dialog>
    </div>
    <!-- show history dialog end here -->
    <!-- Preview screen images start here -->
    <Dialog v-model:visible="imagePreviewDialog" :style="{ width: '1000px' }" header="Preview" :modal="true"
        class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12">
                    <img :src="imgsourcepath" style="vertical-align: middle; width: 100%;" />
                </div>
            </div>
        </div>
    </Dialog>
    <!-- Preview screen images end here -->

    <!----Start of filter--------------------------------->
    <Dialog v-model:visible="filterListingDialog" :style="{ width: '600px' }" header="Filter" :modal="true"
        class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12">
                    <label for="client">Admin</label>
                    <MultiSelect v-model="filterListing.client_fks" :options="clientList" optionValue="value"
                        optionLabel="label" placeholder="Select Admin" :filter="true" class="multiselect-custom"
                        appendTo="body"></MultiSelect>
                </div>
                <div class="p-field p-col-12">
                    <label for="didnumber">
                        Ticket Number
                    </label>
                    <InputText id="ticketnumber" v-model.trim="filterListing.ticketnumber" maxlength="8"
                        @keypress="onlyNumber" />
                </div>
                <div class="p-field p-col-12">
                    <label for="fromdate">Ticket Created Date</label>
                    <Calendar :showIcon="true" :monthNavigator="true" :yearNavigator="true"
                        v-model="filterListing.createdDate" :maxDate="todaysDate" placeholder="DD/MM/YYYY"
                        dateFormat="dd/mm/yy" :yearRange="'2020:' + new Date().getFullYear()" appendTo="body"
                        required="true" :manualInput="false"
                        :class="{ 'p-invalid': submitted && !filterListing.createdDate }"></Calendar>
                </div>
            </div>
        </div>

        <template #footer>
            <Button label="Reset" icon="pi pi-refresh" class="p-button-text" @click="reSetFilteredData()" />
            <Button label="Search" icon="pi pi-search" class="p-mr-2" autofocus
                @click="getFilteredData(filterListing.client_fks, filterListing.ticketnumber, filterListing.createdDate)" />
        </template>
    </Dialog>
    <!----End of filter--------------------------------->
</template>

<script>
import ApiService from '../../service/ApiService';
import useValidate from '@vuelidate/core';
import { } from '@vuelidate/validators';
import moment from 'moment';
export default {
    data() {
        return {
            v$: useValidate(),
            page_no: 0,
            totalRecords: 0,
            loading: false,
            showHistoryDialogStatus: false,
            filterListingDialog: false,
            openTicketList: [],
            submitted: false,
            showFillFilter: false,
            ticketHistoryData: '',
            ticketMessage: '',
            imagePreviewDialog: false,
            imgsourcepath: null,
            myAssignedAndTeamTicketStatus: 2, // 2 for my assigned and 1 for my team assigned ticket
            clientList: [],
            filterListing: {
                client_fks: [],
                ticketnumber: '',
                createdDate: null
            },
            isDisabled: false,
            todaysDate: new Date(),
            showingFullText: false,
            activate_read_key_arr: [],
        };
    },
    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        this.loading = true;
        this.getMyAndTeamAssignTicket({ page_no: this.page_no, status: 3, flag: this.myAssignedAndTeamTicketStatus });
    },
    validations() {
        return {

        };
    },
    methods: {
        activateReadMore(e) {
            this.activate_read_key_arr.push(e);
        },

        deactivateReadMore(e) {
            var index = this.activate_read_key_arr.indexOf(e);
            if (index !== -1) {
                this.activate_read_key_arr.splice(index, 1);
            }
        },
        splitJoin(theText) {
            return theText.split(',');
        },
        previewImageModalOpen(e) {
            this.imagePreviewDialog = true;
            this.imgsourcepath = e;
        },

        getMyAndTeamAssignTicket(ev) {
            this.loading = true;
            this.ApiService.getMyAndTeamAssignTicket(ev).then((data) => {
                if (data.status == 200) {
                    this.openTicketList = data.data;
                    this.totalRecords = data.count;
                } else {
                    this.openTicketList = [];
                }
                this.loading = false;
            });
        },
        onPage(event) {
            this.loading = true;
            this.page_no = event.page;
            this.getMyAndTeamAssignTicket({ page_no: this.page_no, status: 3, flag: this.myAssignedAndTeamTicketStatus, sa4: this.filterListing.ticketnumber, sa2: this.filterListing.client_fks, sa12: this.filterListing.createdDate });
        },
        getMyTickets() {
            this.getMyAndTeamAssignTicket({ page_no: this.page_no, status: 3, flag: 2 });
            this.myAssignedAndTeamTicketStatus = 2;
        },
        getMyTeamsTickets() {
            this.getMyAndTeamAssignTicket({ page_no: this.page_no, status: 3, flag: 1 });
            this.myAssignedAndTeamTicketStatus = 1;
        },

        getTicketsMessages(e) {
            this.ApiService.getTicketsMessage({ sa1: e.sa1, sa2: e.sa2 }).then((data) => {
                if (data.status == 200) {
                    this.ticketMessage = data.data.ticket_messages;
                    this.showHistoryDialogStatus = true;
                } else {
                    this.ticketMessage = null;
                }
                this.loading = false;
            });
        },
        showHistoryDialogOpen(e) {
            this.ticketHistoryData = e;
            this.loading = true;
            this.submitted = false;
            this.getTicketsMessages(e);
        },

        filterlisting() {
            this.getClientList();
            this.filterListingDialog = true;
        },
        getClientList() {
            this.ApiService.getAdminListOnly().then((data) => {
                if (data.status == 200) {
                    this.clientList = data.data.records;
                } else {
                    this.clientList = null;
                }
            });
        },
        onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                $event.preventDefault();
            }
        },
        getFilteredData(client_fks, ticketnumber, createdDate) {
            this.loading = true;
            this.filterListing.client_fks = client_fks;
            this.filterListing.ticketnumber = ticketnumber;
            if (createdDate != "" && createdDate != null) {
                this.filterListing.createdDate = moment(createdDate).format("YYYY-MM-DD");
            }
            this.getMyAndTeamAssignTicket({
                page_no: this.page_no, status: 3, flag: this.myAssignedAndTeamTicketStatus, sa4: ticketnumber, sa2: client_fks, sa12: this.filterListing.createdDate
            });
            this.filterListingDialog = false;
        },
        reSetFilteredData() {
            this.filterListing.client_fks = '';
            this.filterListing.ticketnumber = '';
            this.filterListing.createdDate = '';
            this.filterListingDialog = false;
        },
        triggerCallToMeraNetaClients(data) {
            this.isDisabled = true;
            this.ApiService.triggercalltomeranetaclients({ mobile_number: data.mbk6, sa2: data.sa2, sa4: data.sa4, sa30: data.sa30, sa31: data.sa31 }).then(
                (items) => {
                    if (items.success === true) {
                        this.isDisabled = false;
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                    } else {
                        this.isDisabled = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                }
            );
        },
        format_timestamp(value) {
            if (value) {
                var localTime = new Date(value * 1000).toUTCString();
                return moment(localTime).format("DD/MM/YYYY @ hh:mm A");
            } else {
                return '-';
            }
        },
        working_time(value) {
            let start_time = value.sa13
            let end_time = value.sa32

            let time1 = new Date(start_time * 1000);
            let time2 = new Date(end_time * 1000);
            let diff = time2 - time1;
            let hours = Math.floor(diff / 3.6e6);
            let Days = Math.floor(hours / 24);
            let minutes = Math.floor((diff % 3.6e6) / 6e4);
            if (minutes < 0) {
                return '00' + ':' + '00';
            } else if (Days <= 0) {
                return hours + ' Hours ' + minutes + ' Minutes';
            } else if (Days > 0) {
                var Remainder = hours % 24;
                var Hours = Math.floor(Remainder);
                return Days + ' Days ' + Hours + ' Hours ';
            }
        }

    },
};
</script>

<style scoped lang="scss">
.user-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-active {
        background: #c8e6c9;
        color: #256029;
    }

    &.status-per-suspend {
        background: #ffcdd2;
        color: #c63737;
    }

    &.status-temp-suspend {
        background: #ffd8b2;
        color: #805b36;
    }
}

.custom-scroll {
    background: #e4ded9;
    border-radius: 5px;
    padding: 0.25em 0.5rem;
}

.msg-left {
    float: left;
    width: 80%;
    padding: 5px;
}

.msg-right {
    float: right;
    width: 80%;
    padding: 5px;
}

.arrow {
    width: 12px;
    height: 20px;
    overflow: hidden;
    position: relative;
    float: left;
    top: 6px;
    right: -1px;
}

.arrow .outer {
    width: 0;
    height: 0;
    border-right: 20px solid #000000;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    position: absolute;
    top: 0;
    left: 0;
}

.arrow .inner {
    width: 0;
    height: 0;
    border-right: 20px solid #ffffff;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    position: absolute;
    top: 0;
    left: 2px;
}

.message-body {
    float: left;
    width: 80%;
    height: auto;
    border: 1px solid #CCC;
    background-color: #ffffff;
    border: 1px solid #000000;
    padding: 6px 8px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
}

.message-body p {
    margin: 0;
}

.image {
    float: left;
}

.msg-datetime-left {
    position: relative;
    float: right;
    margin-right: 100px;
}

.msg-datetime-right {
    position: relative;
    float: right;
    margin-right: 100px;
}


.arrow-right {
    width: 12px;
    height: 20px;
    overflow: hidden;
    position: relative;
    float: left;
    top: 6px;
    left: -1px;
}

.arrow-right .outer-right {
    width: 0;
    height: 0;
    border-left: 20px solid #000000;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    position: absolute;
    top: 0;
    right: 0;
}

.arrow-right .inner-right {
    width: 0;
    height: 0;
    border-left: 20px solid #ffffff;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    position: absolute;
    top: 0;
    right: 2px;
}

.custom-btn-class {
    margin-left: 45px;
    margin-bottom: -8px;
}

.custom-status {
    margin-left: 75px;
}

.attachment {
    float: left;
    margin-top: 5px;
}

.attach-file {
    float: left;
    padding: 1px;
}
</style>

<style lang="scss" scoped>
/* Responsive */
.p-datatable-customers .p-datatable-tbody>tr>td .p-column-title {
    display: none;
}

.p-button-xs {
    width: 1rem;
}

.p-button.p-button-xs {
    padding: 0.925rem 0.925rem;
}

.p-button.p-button-xs .p-button-icon {
    font-size: 0.675rem;
}

.p-button.p-button-xs.p-button-icon-only.p-button-rounded {
    height: 1rem;
}

::v-deep(.p-progressbar) {
    height: 1rem;
    background-color: #d8dadc;

    .p-progressbar-value {
        background-color: #689f38;
    }

    .p-progressbar-label {
        display: block;
        color: #ffffff;
        line-height: 13px;
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 960px) {
    ::v-deep(.p-datatable) {
        &.p-datatable-customers {

            .p-datatable-thead>tr>th,
            .p-datatable-tfoot>tr>td {
                display: none !important;
            }

            .p-datatable-tbody>tr {
                border-bottom: 1px solid #dee2e6;

                >td {
                    text-align: left;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 0 none !important;
                    width: 100% !important;
                    float: left;
                    clear: left;
                    border: 0 none;

                    .p-column-title {
                        padding: 0.4rem;
                        min-width: 30%;
                        display: inline-block;
                        margin: -0.4rem 1rem -0.4rem -0.4rem;
                        font-weight: bold;
                    }

                    .p-progressbar {
                        margin-top: 0.5rem;
                    }
                }
            }
        }
    }
}
</style>